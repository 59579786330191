<template>
    <gruaCrud></gruaCrud>
</template>

<script>
import gruaCrud from '@/components/Grua/GruaCrud';

export default {
    name: 'grua',
    data() {
        return {};
    },
    components: {
        gruaCrud
    }
};
</script>
