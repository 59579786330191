<template>
    <v-card>
        <v-card-title class="pa-0">
            <v-toolbar flat dense color="yellow">
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    {{ formTitle }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn text @click="saveItem">Guarda</v-btn>
            </v-toolbar>
        </v-card-title>

        <v-card-text>
            <v-container grid-list-md>
                <v-row>
                    <v-layout wrap dense>
                        <v-flex xs12>
                            <v-text-field v-model="item.nombre"
                                          label="Nombre de campo">
                            </v-text-field>
                        </v-flex>
                    </v-layout>
                </v-row>
            </v-container>
        </v-card-text>

    </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'gruaDialogo',
    data() {
        return {
        };
    },

    computed: {
        ...mapState('grua', ['item']),

        formTitle() {
            return this.index === -1 ? 'Nuevo' : 'Edita';
        }
    },

    methods: {
        ...mapActions('grua', ['closeDialog', 'saveItem']),

        close() {
            this.closeDialog();
        }
    },

    created() {}
};
</script>
